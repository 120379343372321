
<template>
  <!-- 客服电话 -->
  <div
    style="
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <div style="width: 30%; margin-top: 5%">
      <ax-form ref="formBox" :formBuilder="formBuilder"> </ax-form>
    </div>
    <div style="width: 30%; display: flex; justify-content: center">
      <a-button
        type="primary"
        style="background-color: #556bff"
        :loading="saveType"
        @click="confirm"
      >
        提 交
      </a-button>
    </div>
  </div>
</template>
   
   <script>
import api from "./api";
const formList = [
  {
    label: "客服电话1",
    type: "input",
    model: "phoneOne",
    options: { placeholder: "请输入客服电话1", maxLength: 11 },
    col: { span: 24 },
    rules: [
      { required: true, message: "请填写客服电话1", trigger: "blur" },
      {
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入正确的手机号码格式",
        trigger: "blur",
      },
    ],
  },
  {
    label: "客服电话2",
    type: "input",
    model: "phoneTwo",
    options: { placeholder: "请输入客服电话2", maxLength: 11 },
    col: { span: 24 },
    rules: [
      {
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入正确的手机号码格式",
        trigger: "blur",
      },
    ],
  },
];
export default {
  components: {},
  data() {
    return {
      api,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
      userPhone: {},
      saveType: false,
    };
  },

  methods: {
    async phoneNumber() {
      const res = await api.list();
      if (res && res.data.length) {
        console.log(res.data[0]);
        this.$refs.formBox.setFieldsValue(res.data[0]);
        this.userPhone = res.data[0];
      }
    },
    async confirm() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        value = { ...this.userPhone, ...value };
        this.saveType = true;
        try {
          const res = await api.updatePhone(value);
          if (res && res.status == 200) {
            this.$message.success("修改成功");
          } else {
            this.$message.error("修改失败");
          }
          this.saveType = false;
        } catch (error) {
          console.log(error);
          this.saveType = false;
        }
        this.phoneNumber()
      });
    },
  },

  created() {
    this.phoneNumber();
  },

  mounted() {},
};
</script>
   <style lang='less' scoped>
   /deep/.ant-btn-primary{
    width: 30%;
   }
</style>